import { setManifest } from '@angular-architects/module-federation';
import { environment } from './environments/environment';

fetch('/assets/version.json')
  .catch((err) => {
    throw new Error(err);
  })
  .then((versionRes: Response) => {
    versionRes
      .json()
      .catch((err) => {
        throw new Error(err);
      })
      .then((version) => {
        fetch('/assets/config.json')
          .catch((err) => {
            throw new Error(err);
          })
          .then((configRes: Response) => {
            console.log('██╗███╗   ██╗███████╗██╗ ██████╗ ██╗  ██╗████████╗███████╗');
            console.log('██║████╗  ██║██╔════╝██║██╔════╝ ██║  ██║╚══██╔══╝██╔════╝');
            console.log('██║██╔██╗ ██║███████╗██║██║  ███╗███████║   ██║   ███████╗');
            console.log('██║██║╚██╗██║╚════██║██║██║   ██║██╔══██║   ██║   ╚════██║');
            console.log('██║██║ ╚████║███████║██║╚██████╔╝██║  ██║   ██║   ███████║');
            console.log('╚═╝╚═╝  ╚═══╝╚══════╝╚═╝ ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝');
            console.log(`Version: ${version.version}`);
            console.log(`Build: ${environment.production ? 'Production' : 'Development'}`);
            configRes
              .json()
              .catch((err) => {
                throw new Error(err);
              })
              .then((config) => {
                setManifest(config.remoteApplications)
                  .catch((err) => {
                    new Error(err);
                  })
                  .then(() => {
                    import('./bootstrap').then((f) => f.bootstrap(config, version));
                  });
              });
          });
      });
  });
